import React from 'react';
import './WorkStickers.scss';
import classNames from "classnames";

type Sticker = {
    color: 'yellow' | 'purple' | 'green' | 'blue' | 'pink';
    text: string;
};

type WorkStickersProps = {
    pattern: 'football' | 'stickers';
    stickers: Sticker[];
};

const WorkStickers: React.FC<WorkStickersProps> = ({stickers, pattern}) => {
    return (
        <div className={classNames(`WorkStickers ${pattern}`)}>
            <div className="WorkStickers__inner grid grid-cols-2 gap-6 md:grid-cols-[repeat(2,240px)] lg:grid-cols-[repeat(2,240px)] place-content-center">
                {stickers.map(sticker => (
                    <div className="WorkStickers__item shadow-xl" style={{backgroundColor: `var(--color-${sticker.color})`}}>{sticker.text}</div>
                ))}
            </div>
        </div>
    );
}

export default WorkStickers;
