import React from 'react';
import Picture from "../../Picture/Picture";
import './WorkScreenshots.scss';
import useIsMobile from "../../../hooks/useIsMobile";
import WorkSlider from "../WorkSlider/WorkSlider";

type Props = {
    images: string[];
    color: 'blue' | 'green' | 'gray-light' | 'blue-light';
};

const WorkScreenshots: React.FC<Props> = ({images, color}) => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return <WorkSlider images={images}/>
    }

    return (
        <div className="WorkScreenshots" style={{backgroundColor: `var(--color-${color})`}}>
            {images.map(img => (
                <Picture className="WorkScreenshots__picture" imgSrc={img}/>
            ))}
        </div>
    );
}

export default WorkScreenshots;
