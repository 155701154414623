import React from 'react';
import './WorkTitle.scss';

type WorkTitleProps = {
    children: React.ReactNode;
};

const WorkTitle: React.FC<WorkTitleProps> = ({children}) => {
    return (
        <h1 className="WorkTitle">{children}</h1>
    );
}

export default WorkTitle;
