import React, {HTMLAttributeAnchorTarget} from 'react';
import './Tile.scss';
import {NavLink, To} from "react-router-dom";
import Icon from "../Icon/Icon";
import classNames from "classnames";

type IconProps = {
    to?: To;
    target?: HTMLAttributeAnchorTarget;
    title?: string;
    isTitleBelow?: boolean;
    isTitleSmall?: boolean;
    className?: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
};

const Tile: React.FC<IconProps> = (
    {
        to,
        target,
        title,
        className,
        children,
        footer,
        isTitleSmall,
        isTitleBelow
    }) => {

    const content = () => (
        <>
            {title && (
                <div
                    className={classNames("Tile__title", {"Tile__title--below": isTitleBelow}, {"Tile__title--small": isTitleSmall})}>
                    <h2>{title}</h2>

                    {to && (
                        <span className="Tile__title-icon"><Icon name="arrow-up-right"/></span>
                    )}
                </div>
            )}

            <div className="Tile__content">{children}</div>
            {footer && <div className="Tile__footer">{footer}</div>}
        </>
    )

    return (
        <>
            {to && <NavLink to={to} target={target} className={classNames("Tile", className)}>{content()}</NavLink>}
            {!to && <div className={classNames("Tile", className)}>{content()}</div>}
        </>
    );
}

export default Tile;
