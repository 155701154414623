import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";

import AIMentalHealthAppImage from "./images/AIMentalHealthApp.png";
import AIMentalHealthAppImage1 from "./images/AIMentalHealthApp-1.png";
import AIMentalHealthAppImage2 from "./images/AIMentalHealthApp-2.png";
import AIMentalHealthAppImage3 from "./images/AIMentalHealthApp-3.png";
import AIMentalHealthAppImage4 from "./images/AIMentalHealthApp-4.png";
import AIMentalHealthAppImage5 from "./images/AIMentalHealthApp-5.png";
import AIMentalHealthAppImage6 from "./images/AIMentalHealthApp-6.png";
import AIMentalHealthAppImage7 from "./images/AIMentalHealthApp-7.png";
import AIMentalHealthAppImage8 from "./images/AIMentalHealthApp-8.png";
import AIMentalHealthAppImage9 from "./images/AIMentalHealthApp-9.png";
import AIMentalHealthAppImage10 from "./images/AIMentalHealthApp-10.png";
import AIMentalHealthAppImage11 from "./images/AIMentalHealthApp-11.png";
import AIMentalHealthAppImage12 from "./images/AIMentalHealthApp-12.png";
import AIMentalHealthAppImage13 from "./images/AIMentalHealthApp-13.png";
import AIMentalHealthAppImage14 from "./images/AIMentalHealthApp-14.png";
import AIMentalHealthAppImage15 from "./images/AIMentalHealthApp-15.png";
import WorkStickers from "../../../components/Work/WorkStickers/WorkStickers";
import WorkTable from "../../../components/Work/WorkTable/WorkTable";
import WorkSlider from "../../../components/Work/WorkSlider/WorkSlider";
import WorkScreenshots from "../../../components/Work/WorkScreenshots/WorkScreenshots";
import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";

function AIMentalHealthApp() {
    return (
        <section className="container container-work">
            <WorkTitle>
                AI-Based Mobile App for Mental Health Support Targeting Black Individuals with PTSD and ADHD, Especially
                Veterans 🧘🏾 📱
            </WorkTitle>

            <WorkText title="My role" hasSeparator>UX/UI Product Designer</WorkText>

            <WorkText title="Team">
                Doctor of Medical Sciences Co-founder, Veteran Founder,<br/> Developer specialized in AI
            </WorkText>

            <WorkBanner images={[AIMentalHealthAppImage4, AIMentalHealthAppImage3, AIMentalHealthAppImage5]}/>

            <WorkText title="🔍 Research" hasSeparator>
                <p>
                    Studies have indicated a significant disparity in mental health support and understanding for Black
                    individuals. These findings underscore the systemic barriers and stigma that often lead to
                    underdiagnosed and undertreated mental health conditions among this group.
                </p>
                <p>
                    Specifically, Black individuals with PTSD and ADHD face unique challenges, including higher rates of
                    misdiagnosis, a lack of culturally competent care, and limited access to resources.
                </p>
                <p>
                    This gap in care and support is even more pronounced among Black veterans, who not only deal with
                    the racial inequities present in civilian mental health care but also navigate the complexities of
                    PTSD related to military service.
                </p>
            </WorkText>

            <WorkStickers pattern="stickers" stickers={[
                {
                    color: 'yellow',
                    text: 'Black individuals are more likely to be misdiagnosed compared to other groups'
                },
                {
                    color: 'blue',
                    text: 'The absence of cultural competence can increase mistrust and refusal of treatment'
                },
                {color: 'purple', text: 'Black veterans may face greater difficulties in accessing quality care'},
                {color: 'green', text: 'Black individuals may not have access to the necessary support and treatment'}
            ]}/>

            <WorkText title="💬 Interviews">
                <p>
                    Qualitative interviews with affected individuals revealed feelings of isolation, misunderstanding,
                    and a dire need for culturally sensitive and accessible mental health resources. Participants
                    expressed a desire for tools that offer personalized support, understanding, and acknowledgement of
                    their unique experiences and challenges.
                </p>
                <p>
                    There was a particular emphasis on the importance of a community that resonates with their cultural
                    and personal identities.
                </p>
            </WorkText>

            <WorkTable
                color='yellow'
                thead={['🗣️ User Experiences', ' ❤️ User Needs', '💡 Insights']}
                tbody={[
                    [
                        '"The need to feel connected with others who understand their struggle."',
                        'I often feel isolated, like I’m fighting this battle alone.',
                        'Creating a community within the app where users can find mutual understanding and support.'
                    ],
                    [
                        '"Doctors don’t always get me, it causes stress in treatment."',
                        'The desire to be heard and understood, considering their cultural background and personal history.',
                        'Developing an app with a focus on cultural nuances and individual experiences.'
                    ],
                    [
                        '"I need help that accounts for who I am."',
                        'The need for personalized assistance that matches personal characteristics.',
                        'Ensuring personalization in the app so that users feel the service is tailored to them.'
                    ],
                    [
                        '"It’s hard to find a community where I don’t feel like the other."',
                        'The need for a community that shares personal values and experiences.',
                        'Integrating app functions to connect users with similar stories and experiences.'
                    ],
                    [
                        '"I want my story to be part of my healing."',
                        'The need for acknowledgment of their unique life journey in the healing process.',
                        'Creating a mechanism in the app for incorporating personal stories into the healing process.'
                    ]
                ]}
            />

            <WorkText title="🛠 Features">
                Drawing from the insights gained from interviews and literature, the app is designed with features to
                meet the identified needs: meditation guides, mood tracking, soothing modules, daily AI-generated
                recommendations, a gratitude journal, goal setting, access to resources, and continuous updates of
                content and recommendations based on mood tracking. This comprehensive suite of features aims to provide
                a holistic approach to mental health care for Black individuals with PTSD and ADHD.
            </WorkText>
            <WorkSlider images={[
                AIMentalHealthAppImage,
                AIMentalHealthAppImage1,
                AIMentalHealthAppImage2,
                AIMentalHealthAppImage3,
                AIMentalHealthAppImage4,
                AIMentalHealthAppImage5,
                AIMentalHealthAppImage6,
                AIMentalHealthAppImage7,
                AIMentalHealthAppImage8,
                AIMentalHealthAppImage9,
                AIMentalHealthAppImage10,
                AIMentalHealthAppImage11,
                AIMentalHealthAppImage12
            ]}/>

            <WorkText title="💡 Concept Idea">
                The design of the app is crafted to "speak" to the user, fostering a one-on-one conversation that
                resonates on a personal level. It emphasizes community and support, mirroring the kind of interactions
                that affirm the user's feelings and experiences, much like the engaging and supportive prompts seen in
                the screenshots.
            </WorkText>
            <WorkScreenshots color="blue"
                             images={[AIMentalHealthAppImage13, AIMentalHealthAppImage14, AIMentalHealthAppImage15]}/>

            <WorkText title="📱 Final Product">
                The app boasts a simple, minimalist design that prioritizes user control over recommendations, crucial
                for individuals with these conditions. It stands out by its adaptive and user-friendly interface,
                allowing for a personalized and inclusive mental health care experience. This product not only addresses
                the gap in mental health resources for Black individuals with PTSD and ADHD but also sets a new standard
                for inclusive and culturally competent mental health support.
            </WorkText>
            <WorkFigma src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQqbv4NKh0SVGZazFJrgIpq%2FBalance-Mobile-App%3Ftype%3Ddesign%26node-id%3D847%253A17211%26mode%3Ddesign%26t%3D3tVqtsvGVWTrAnVD-1"/>
        </section>
    );
}

export default AIMentalHealthApp;
