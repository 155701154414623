import React from 'react';
import './Tag.scss';
import Icon, {IconName} from "../Icon/Icon";
import {Link, To} from "react-router-dom";

type TagProps = {
    className?: string;
    to: To;
    children: React.ReactNode;
    icons: IconName[];
};

const Tag: React.FC<TagProps> = ({children, icons, to}) => {
    return (
        <Link to={to} target="_blank">
            <div className="Tag">
                {icons.map((icon, index) => (
                    <Icon className="Tag__icon" key={index + icon} name={icon}/>
                ))}
                {children}
            </div>
        </Link>
    );
}

export default Tag;
