import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";

import CorporateBankingSystem1 from "./images/CorporateBankingSystem1.jpg";
import CorporateBankingSystem2 from "./images/CorporateBankingSystem2.jpg";
import CorporateBankingSystem3 from "./images/CorporateBankingSystem3.jpg";
import CorporateBankingSystem4 from "./images/CorporateBankingSystem4.jpg";

import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";
import WorkImage from "../../../components/Work/WorkImage/WorkImage";
import WorkScreenshots from "../../../components/Work/WorkScreenshots/WorkScreenshots";

function CorporateBankingSystem() {
    return (
        <section className="container container-work">
            <WorkTitle>
                Corporate Banking System for Money Transfers 🏦 💸
            </WorkTitle>

            <WorkText title="My role" hasSeparator>UX/UI Designer</WorkText>

            <WorkText title="Team">
                Company CEO, Product Manager, Developers
            </WorkText>

            <WorkBanner images={[CorporateBankingSystem1]} autoHeight/>

            <WorkText title="🎯 Objective" hasSeparator>
                The primary challenge was to create an intuitive pathway from user registration to account activation.
                Additionally, crafting a system that was both understandable and easy to use was a priority.
            </WorkText>
            <WorkImage src={CorporateBankingSystem2} autoHeight/>

            <WorkText title="👩‍💻 Process">
                I researched similar systems at the stage of registration, information gathering, and activation to
                identify the most effective methods. Collaboratively, our team selected the user flow that best suited
                our business objectives from the proposed variants.
            </WorkText>
            <WorkFigma
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F3tzwgQhfDclUnGYc6W0vZ9%2FFintech-Bank-(NDA)%3Ftype%3Ddesign%26node-id%3D5%253A4%26mode%3Ddesign%26t%3DLpeYdQs7XxI6ucfU-1"/>

            <WorkText title="⚙️ Functionality">
                The platform integrates essential services for comprehensive financial management. It includes a
                Payments module for handling transactions, transfers, and beneficiary management; an Expense module to
                track invoices, bills, and card activities; a Settings hub for security settings, notifications, and
                subscription details; and a Profile section that houses business specifics and branding options.
            </WorkText>
            <WorkImage src={CorporateBankingSystem3} autoHeight/>

            <WorkText title="👩‍🎨 Design">
                The design, as per the client's request, is minimalist, clean, and classic, incorporating the brand’s
                colors. The dashboard played a crucial role; I designed it to allow users quick access to their primary
                processes and essential information at a glance.
            </WorkText>
            <WorkImage src={CorporateBankingSystem4} autoHeight/>

            <WorkText title="📊 Final Product">
                As a result, the company received a comprehensive, user-friendly banking platform that simplifies
                financial transactions for corporate users. It offers a robust overview of financial health through the
                dashboard and ensures seamless management of financial operations, empowering businesses with the tools
                needed for efficient financial governance.
            </WorkText>
            <WorkFigma
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F3tzwgQhfDclUnGYc6W0vZ9%2FFintech-Bank-(NDA)%3Ftype%3Ddesign%26node-id%3D376%253A750%26mode%3Ddesign%26t%3DLpeYdQs7XxI6ucfU-1"/>
        </section>
    );
}

export default CorporateBankingSystem;
