import React, {useEffect, useState} from 'react';
import './TileFooter.scss';
import Picture from "../../Picture/Picture";
import TileFooterPhoto from './tile-footer-photo.jpg';

function getCurrentTimeInTimezone(timezone: string) {
    return new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: undefined,
        timeZone: timezone,
        hour12: false
    }).format(new Date());
}

function TileFooter() {
    const [currentTime, setCurrentTime] = useState(getCurrentTimeInTimezone('Europe/Warsaw'));

    useEffect(() => {
        const timerId = setInterval(() => {
            setCurrentTime(getCurrentTimeInTimezone('Europe/Warsaw'));
        }, 1000);

        return () => {
            clearInterval(timerId);
        };
    }, []);

    return (
        <div className="TileFooter">
            <Picture className="TileFooter__photo" imgSrc={TileFooterPhoto} title="Tile footer photo"/>
            Based in Katowice, Poland:&nbsp;<strong>{currentTime}</strong>
        </div>
    );
}

export default TileFooter;
