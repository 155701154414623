import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";
import WorkSlider from "../../../components/Work/WorkSlider/WorkSlider";
import WorkScreenshots from "../../../components/Work/WorkScreenshots/WorkScreenshots";
import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";
import WorkVideo from "../../../components/Work/WorkVideo/WorkVideo";

import MobileAppForMeditationsImage from "./images/MobileAppForMeditations.png";
import MobileAppForMeditationsImage1 from "./images/MobileAppForMeditations-1.png";
import MobileAppForMeditationsImage2 from "./images/MobileAppForMeditations-2.png";
import MobileAppForMeditationsImage3 from "./images/MobileAppForMeditations-3.png";
import MobileAppForMeditationsImage4 from "./images/MobileAppForMeditations-4.png";
import MobileAppForMeditationsImage5 from "./images/MobileAppForMeditations-5.png";
import MobileAppForMeditationsImage6 from "./images/MobileAppForMeditations-6.png";
import MobileAppForMeditationsImage7 from "./images/MobileAppForMeditations-7.png";
import MobileAppForMeditationsImage8 from "./images/MobileAppForMeditations-8.png";
import MobileAppForMeditationsImage9 from "./images/MobileAppForMeditations-9.png";
import MobileAppForMeditationsImage10 from "./images/MobileAppForMeditations-10.png";

// @ts-ignore
import MobileAppForMeditationsVideo from "./MobileAppForMeditations.mp4";

function MobileAppForMeditations() {
    return (
        <section className="container container-work">
            <WorkTitle>Mobile App for Meditations 🧘🏻‍♀️📱</WorkTitle>
            <WorkText title="My role" hasSeparator>UX/UI Product Designer</WorkText>
            <WorkText title="Team">Startup Founder</WorkText>

            <WorkVideo src={MobileAppForMeditationsVideo}/>
            {/*<WorkBanner*/}
            {/*    images={[MobileAppForMeditationsImage4, MobileAppForMeditationsImage3, MobileAppForMeditationsImage5]}/>*/}

            <WorkText title="🌞 Overview" hasSeparator>
                Sunnyside offers an engaging and user-friendly platform designed to promote daily mental health
                practices through an appealing and bright interface. With its charming sun mascot and a calming blue sky
                background, it brings a sense of warmth and serenity to users' routines.
            </WorkText>
            <WorkScreenshots color="gray-light"
                             images={[MobileAppForMeditationsImage1, MobileAppForMeditationsImage2, MobileAppForMeditationsImage3]}/>

            <WorkText title="🛠 Features">
                Sunnyside provides daily planning tools, meditation sessions, a variety of relaxing soundscapes,
                breathwork exercises, and informative articles aimed at enhancing the user's day-to-day mental health.
            </WorkText>
            <WorkSlider images={[
                MobileAppForMeditationsImage4,
                MobileAppForMeditationsImage8,
                MobileAppForMeditationsImage,
                MobileAppForMeditationsImage3,
                MobileAppForMeditationsImage6,
                MobileAppForMeditationsImage1,
                MobileAppForMeditationsImage2,
                MobileAppForMeditationsImage5,
                MobileAppForMeditationsImage7,
                MobileAppForMeditationsImage9,
            ]}/>

            <WorkText title="💡 Concept Idea">
                The app is conceived as a cheerful companion, fostering a sense of optimism and support through its
                sunny design and user-centric approach.
            </WorkText>
            <WorkScreenshots color="blue-light"
                             images={[MobileAppForMeditationsImage, MobileAppForMeditationsImage9, MobileAppForMeditationsImage10]}/>


            <WorkText title="📱 Final Product">
                Sunnyside showcases a simplistic and intuitive design that encourages users to engage in self-care
                practices. It's a thoughtful amalgamation of resources that supports users with structured daily plans,
                relaxation, and educational content.
            </WorkText>
            <WorkFigma
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FAYGsj6cIMKDk8EFSMKafLd%2FMeditation-App%3Ftype%3Ddesign%26node-id%3D76%253A811%26mode%3Ddesign%26t%3Dt10ruBb1GGLBwJJ1-1"/>
        </section>
    );
}

export default MobileAppForMeditations;
