import React from 'react';
import Tile from "../../components/Tile/Tile";
import './About.scss';
import PhotoGallery from "../../components/PhotoGallery/PhotoGallery";
import Tag from '../../components/Tag/Tag';
import InstagramTile from "../../components/Tile/InstagramTile/InstagramTile";
import LinkedinTile from "../../components/Tile/LinkedinTile/LinkedinTile";

function About() {
    return (
        <section className="About">
            <div className="container">
                <div className="grid gap-6">
                    <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                        <div className="grid gap-6">
                            <Tile title="Feel free to reach out to me through whichever method you prefer" isTitleSmall>
                                <div className="About__tags">
                                    <Tag to="https://t.me/nusevich" icons={["telegram"]}>@nusevich</Tag>
                                    <Tag to="mailto:nataliusevich2.0@gmail.com"
                                         icons={["google"]}>nataliusevich2.0@gmail.com</Tag>
                                    <Tag to="tel:+48571447074" icons={["whatsapp", "messenger"]}>+48 571 447 074</Tag>
                                </div>
                            </Tile>

                            <Tile>
                                <h3>My approach to design</h3>
                                <p>
                                    I prefer practical solutions tailored to each project's specific needs. Rather than
                                    adhering strictly to established methodologies like Jobs to Be Done or Customer
                                    Journey Maps, I prioritize efficiency and direct engagement with users. My focus is
                                    on gathering actionable insights through techniques like interviews and surveys,
                                    avoiding trends and unnecessary complexity.
                                </p>

                                <p>
                                    I'm deeply attuned to users' needs and pains, often finding that asking the right
                                    questions is sufficient to identify real problems and offer valuable solutions.
                                </p>

                                <h3>How I started my journey in UX design</h3>
                                <p>
                                    After graduating from college, I started my own project translating subtitles for TV
                                    series and movies. Eventually, I built a team of 60 translators and managed this
                                    project for 7 years. I became interested in UX design when it was time to develop my
                                    own website.
                                </p>
                                <p>
                                    Later on, I left my project (for countless reasons) and began my path in UX design.
                                    The website for this project became my first design project.
                                </p>

                                <h3>Where I'm from and where I studied</h3>
                                <p>
                                    I was born and raised in Ukraine. I've always been drawn to modern technologies, and
                                    despite financial difficulties, I managed to get into the College of the Kyiv
                                    Aviation University, where I earned a diploma in Desktop Publishing Systems.
                                </p>
                                <p>
                                    I remember how my classmates and I designed children's coloring books and delivered
                                    printed copies to orphanages. That was my first experience with design for people.
                                </p>
                            </Tile>
                        </div>
                        <div className="grid gap-6">
                            <div className="grid gap-6 grid-cols-1">
                                <Tile title="My life in the photo gallery" isTitleSmall>
                                    <PhotoGallery/>
                                </Tile>

                                <Tile>
                                    <h3>Here's how I'm rolling these days</h3>
                                    <p>I'm running an <strong>Insta blog</strong> where I'm all about helping newbies
                                        break
                                        into
                                        the UX design scene.</p>
                                    <p>
                                        Also, I'm hosting <strong>meetups for Ukrainian ladies</strong> in my city,
                                        helping
                                        them
                                        settle in after moving due to the war.
                                    </p>
                                    <p>
                                        Whenever I catch a breather, I'm <strong>diving into books</strong> and curating
                                        the
                                        best for my library.
                                    </p>
                                    <p>
                                        And whenever I get the chance, I'm <strong>jet-setting around the globe</strong>,
                                        soaking in its beauty.
                                    </p>
                                </Tile>
                            </div>

                            <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                                <InstagramTile hasBigImage/>
                                <LinkedinTile hasBigImage/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
