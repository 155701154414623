import React from 'react';
import './Picture.scss';
import classNames from "classnames";
import 'lightgallery/css/lightgallery.css';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';

function Picture({title, className, imgSrc, imgWebpSrc, hasBorderRadius}: {title?: string, imgSrc: string, imgWebpSrc?: string, className?: string, hasBorderRadius?: boolean}) {
    return (
        <picture className={classNames("Picture", className, {"Picture--borderRadius": hasBorderRadius})}>
            {imgWebpSrc && <source srcSet={imgWebpSrc} type="image/webp"/>}
            <img src={imgSrc} />

            {/*<LightGallery*/}
            {/*    speed={500}*/}
            {/*    plugins={[lgZoom]}*/}
            {/*    counter={false}*/}
            {/*    download={false}*/}
            {/*>*/}
            {/*    <a href={imgSrc}>*/}
            {/*        <img src={imgSrc} />*/}
            {/*    </a>*/}
            {/*</LightGallery>*/}
        </picture>
    );
}

export default Picture;
