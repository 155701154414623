import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";

import OnlineStoreOfBrandedClothesBanner1 from "./images/OnlineStoreOfBrandedClothesBanner1.png";
import OnlineStoreOfBrandedClothesBanner2 from "./images/OnlineStoreOfBrandedClothesBanner2.png";
import OnlineStoreOfBrandedClothesProductPage1 from "./images/OnlineStoreOfBrandedClothesProductPage1.jpg";
import OnlineStoreOfBrandedClothesProductPage2 from "./images/OnlineStoreOfBrandedClothesProductPage2.jpg";
import OnlineStoreOfBrandedClothesProductPage3 from "./images/OnlineStoreOfBrandedClothesProductPage3.jpg";
import OnlineStoreOfBrandedClothesProductPage4 from "./images/OnlineStoreOfBrandedClothesProductPage4.jpg";
import OnlineStoreOfBrandedClothesScreenshot1 from "./images/OnlineStoreOfBrandedClothesScreenshot1.png";
import OnlineStoreOfBrandedClothesScreenshot2 from "./images/OnlineStoreOfBrandedClothesScreenshot2.png";
import OnlineStoreOfBrandedClothesScreenshot3 from "./images/OnlineStoreOfBrandedClothesScreenshot3.png";

import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";
import WorkImage from "../../../components/Work/WorkImage/WorkImage";
import WorkScreenshots from "../../../components/Work/WorkScreenshots/WorkScreenshots";

function OnlineStoreOfBrandedClothes() {
    return (
        <section className="container container-work">
            <WorkTitle>
                Redesign of an Online Designer Clothing Store: Web and Mobile Versions 👠👜
            </WorkTitle>

            <WorkText title="My role" hasSeparator>UX/UI Designer</WorkText>

            <WorkText title="Team">
                Customer, Developers
            </WorkText>

            <WorkBanner images={[OnlineStoreOfBrandedClothesBanner2, OnlineStoreOfBrandedClothesBanner1]}/>

            <WorkText title="🔍 UX Review" hasSeparator>
                The website was previously cluttered with excessive black lines, and it suffered from confusing
                navigation and a convoluted order placement flow. To enhance user experience, I reduced the number of
                lines to minimize eye strain, allowing users to browse clothing for longer periods comfortably.
                Additionally, I developed a clearer navigation system and simplified the order processing path.
            </WorkText>

            <WorkImage src={OnlineStoreOfBrandedClothesProductPage4} compareSrc={OnlineStoreOfBrandedClothesProductPage3}></WorkImage>

            <WorkText title="🔍 UX Review" hasSeparator>
                In the redesigned version, as seen in the second screenshot, the search feature exemplifies the broader
                improvements made across the site. Similar to other enhancements, it has been refined to present a clean
                and intuitive user interface. This particular improvement showcases a consolidated view of recent search
                queries and popular products on the homepage, which mirrors the systematic simplification applied to
                other problematic areas in the design. By addressing issues in a consistent manner, the redesign
                elevates the overall user experience, making navigation and product discovery more fluid and less
                time-consuming for the shopper.
            </WorkText>

            <WorkImage src={OnlineStoreOfBrandedClothesProductPage2} compareSrc={OnlineStoreOfBrandedClothesProductPage1}></WorkImage>

            <WorkText title="📱 Mobile">
                Special attention has been devoted to refining the order flow in the redesign, particularly with the
                goal of simplifying the process for mobile users. Recognizing that many people prefer to browse and shop
                for clothing using their phones, potentially while relaxing on a couch, the mobile version has been
                optimized for ease of use. This entails a smoother transition from selecting items to finalizing the
                purchase. The design ensures that from the moment a user adds an item to their shopping bag to the point
                of checkout, the steps are clear, intuitive, and require minimal input, thus enhancing the overall
                mobile shopping experience. This approach addresses and streamlines the complexities previously
                encountered, making the process of ordering as effortless as possible.
            </WorkText>
            <WorkScreenshots color="gray-light"
                             images={[OnlineStoreOfBrandedClothesScreenshot3, OnlineStoreOfBrandedClothesScreenshot1, OnlineStoreOfBrandedClothesScreenshot2]}/>


            <WorkText title="📱 Final Product">
                It offers a visually clean interface, intuitive navigation, and a simplified checkout process, catering
                to the growing trend of mobile commerce.
            </WorkText>
            <WorkFigma src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FcbITmkMT4mwiVDKndewLt5%2FBrand-Store%3Ftype%3Ddesign%26node-id%3D1%253A206%26mode%3Ddesign%26t%3DctUvyAOXx4HcApDL-1"/>
        </section>
    );
}

export default OnlineStoreOfBrandedClothes;
