import { useState, useEffect } from 'react';

// Custom hook to determine if the current window size is for mobile devices
const useIsMobile = (threshold = 768) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < threshold);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < threshold);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [threshold]);

    return isMobile;
};

export default useIsMobile;
