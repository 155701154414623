import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";

import FitnessTrainerSearchWebsite1 from "./images/FitnessTrainerSearchWebsite1.jpg";
import FitnessTrainerSearchWebsite2 from "./images/FitnessTrainerSearchWebsite2.jpg";
import FitnessTrainerSearchWebsite3 from "./images/FitnessTrainerSearchWebsite3.jpg";

import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";
import WorkImage from "../../../components/Work/WorkImage/WorkImage";

function LMSForAfghanGirls() {
    return (
        <section className="container container-work">
            <WorkTitle>
                Fitness Trainer Search Website 🧘‍♀️ 🏡
            </WorkTitle>

            <WorkText title="My role" hasSeparator>UX/UI, Web Designer</WorkText>

            <WorkText title="Team">
                Product Owners, Developers
            </WorkText>

            <WorkBanner images={[FitnessTrainerSearchWebsite1]} autoHeight isWithoutBG/>

            <WorkText title="🤸‍♀️ Overview" hasSeparator>
                This service is designed for users to find fitness trainers for online home workouts. The task was to
                create an appealing and stylish website to attract new users.
            </WorkText>
            <WorkFigma
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FzoC3npNQkPxMME025JExoR%2FFitness-(Copy)%3Ftype%3Ddesign%26node-id%3D85-331%26t%3Dp8UUrqjvAzDJv55W-1%26scaling%3Dscale-down-width%26page-id%3D0%253A1%26starting-point-node-id%3D85%253A331%26mode%3Ddesign"/>

            <WorkText title="🏗 Structure">
                The website comprises several key sections:
                <ul>
                    <li>
                        💁‍ About Us: Introduces the company's mission, values, and the people behind the brand.
                    </li>
                    <li>
                        🧘‍♂️ Trainers: Showcases certified trainers with profiles detailing their specialties and experience.
                    </li>
                    <li>
                        🏋‍ Programs: Outlines various training programs available, such as yoga, functional training, weight loss, and stretching & mobility.
                    </li>
                    <li>
                        🫰 Prices: Lists the pricing plans for different durations of training sessions.
                    </li>
                    <li>
                        ❓ FAQ: Answers frequently asked questions to help users understand the service and address common concerns.
                    </li>
                    <li>
                        🤙 Start Now: A call-to-action button for users ready to sign up and begin their fitness journey.
                    </li>
                </ul>
            </WorkText>
            <WorkImage src={FitnessTrainerSearchWebsite2} autoHeight/>

            <WorkText title="💡 Style">
                The website style is sleek and modern with a user-friendly interface. It uses a monochromatic color
                scheme with accents in red, which adds a dynamic touch. High-quality images and graphics are used
                throughout to engage users and provide a realistic preview of the training experience.
            </WorkText>
            <WorkImage src={FitnessTrainerSearchWebsite3} autoHeight/>

            <WorkText title="⚡ Final Product">
                The final product is a visually compelling and intuitive platform that streamlines the process of
                finding a personal trainer. It effectively addresses the needs of potential clients by offering a
                variety of programs and transparent pricing. The inclusion of testimonials adds credibility, and the FAQ
                section anticipates user inquiries, making the site not only a portal for services but also a resource
                for fitness information. This comprehensive approach is poised to bolster business growth by providing a
                standout user experience that resonates with the target audience's desire for fitness accessibility and
                personalized guidance.
            </WorkText>

            <WorkFigma
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FzoC3npNQkPxMME025JExoR%2FFitness-(Copy)%3Ftype%3Ddesign%26node-id%3D193%253A2%26mode%3Ddesign%26t%3D5Dzw7gYMraLKKkHS-1"/>
        </section>
    );
}

export default LMSForAfghanGirls;
