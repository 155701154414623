import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";

import LMSForAfghanGirlsItem1 from "./images/LMSForAfghanGirlsItem1.jpg";
import LMSForAfghanGirlsItem2 from "./images/LMSForAfghanGirlsItem2.jpg";
import LMSForAfghanGirlsItem3 from "./images/LMSForAfghanGirlsItem3.jpg";
import LMSForAfghanGirlsItem4 from "./images/LMSForAfghanGirlsItem4.jpg";
import LMSForAfghanGirlsItem5 from "./images/LMSForAfghanGirlsItem5.jpg";
import LMSForAfghanGirlsItem6 from "./images/LMSForAfghanGirlsItem6.jpg";

import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";
import WorkImage from "../../../components/Work/WorkImage/WorkImage";
import WorkScreenshots from "../../../components/Work/WorkScreenshots/WorkScreenshots";

function LMSForAfghanGirls() {
    return (
        <section className="container container-work">
            <WorkTitle>
                Learning System with Courses and Mentors for Afghan Girls🧕💻
            </WorkTitle>

            <WorkText title="My role" hasSeparator>UX/UI Product Designer</WorkText>

            <WorkText title="Team">
                Product Owners, Developers
            </WorkText>

            <WorkBanner images={[LMSForAfghanGirlsItem1]} autoHeight/>

            <WorkText title="🎯 Task" hasSeparator>
                The challenge was to create a traditional Learning Management System (LMS) tailored to Afghanistan. It
                was essential to study and consider the cultural nuances and needs of Afghan girls. To this end, I
                worked closely with the product owner, who provided invaluable insights into the local culture.
                Together, we sought to translate these cultural elements into the design style of the LMS, ensuring that
                the platform was not only functional but also resonated with the users it aimed to serve.
            </WorkText>
            <WorkImage src={LMSForAfghanGirlsItem2} autoHeight/>

            <WorkText title="📚 Functionality">
                The system includes an Academy with courses and mentors for adults and a School with lessons and
                teachers for children. The Academy provides vocational and skill-based courses, while the School offers
                basic education modules, both incorporating local language support and offline accessibility.
            </WorkText>
            <WorkImage src={LMSForAfghanGirlsItem3} autoHeight/>
            <WorkImage src={LMSForAfghanGirlsItem4} autoHeight/>

            <WorkText title="✍ Additionally">
                Landing pages were also developed to effectively communicate the value proposition of the educational
                platform. These pages were carefully crafted to showcase the courses and mentorship programs, with clear
                calls to action that facilitate user registration. Special attention was given to ensuring that the
                imagery and messaging were empowering and spoke directly to the aspirations and needs of Afghan girls.
            </WorkText>
            <WorkImage src={LMSForAfghanGirlsItem5} autoHeight/>

            <WorkText title="💡 Concept Idea">
                The style of the platform marries the intricate patterns of Afghan architecture with modern design
                elements, serving as a metaphorical 'window to knowledge.' The vibrant colors and arch-shaped frames,
                inspired by traditional Afghan windows, add a cultural touch while representing the educational
                opportunities offered to the learners. This design celebrates heritage and symbolizes the opening of new
                horizons through education.
            </WorkText>
            <WorkImage src={LMSForAfghanGirlsItem6} autoHeight/>

            <WorkText title="📊 Final Product">
                As a result, the company received a platform that not only facilitates education but also celebrates the
                cultural identity of its users. It offers a safe, culturally respectful environment for Afghan girls to
                learn and grow, with tools that address their specific life circumstances and empower them through
                education.
            </WorkText>
            <WorkFigma
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FiuzE5kv4jQqY64nABQb3kc%2FEdu-LMS-(NDA)%3Ftype%3Ddesign%26node-id%3D1%253A14239%26mode%3Ddesign%26t%3DjgFmqeIjyZNGnGm1-1"/>
        </section>
    );
}

export default LMSForAfghanGirls;
