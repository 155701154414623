import React from 'react';
import Picture from "../../Picture/Picture";
import './WorkSlider.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, EffectCreative, Navigation, Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';

type WorkBannerProps = {
    images: string[];
};

const WorkSlider: React.FC<WorkBannerProps> = ({images}) => {
    return (
        <div className="WorkSlider">
            <div className="WorkSlider__inner">
                <Swiper
                    modules={[Autoplay, EffectCreative, Navigation, Pagination]}
                    slidesPerView={1}
                    centeredSlides={true}
                    loop={true}
                    navigation={true}
                    breakpoints={{
                        // when window width is >= 480px
                        480: {
                            slidesPerView: 2,
                        },
                        // when window width is >= 640px
                        768: {
                            slidesPerView: 3,
                        }
                    }}
                    pagination={{
                        type: 'fraction',
                        el: '.swiper-custom-pagination',
                    }}
                    autoplay={{delay: 2500, pauseOnMouseEnter: true}}
                >
                    {images.map(img => (
                        <SwiperSlide>
                            <Picture className="WorkSlider__item" imgSrc={img}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="swiper-custom-pagination"/>
        </div>
    );
}

export default WorkSlider;
