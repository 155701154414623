import React from 'react';
import './WorkTable.scss';

type Row = string[];

type Props = {
    color: 'yellow' | 'purple' | 'green' | 'green-2' | 'blue';
    thead: string[]
    tbody: Row[]
};

const WorkTable: React.FC<Props> = ({thead, tbody, color}) => {
    return (
        <div className="WorkTable">
            <table className="rounded-corners">
                <thead>
                    <tr>
                        {thead.map(col => (<th style={{backgroundColor: `var(--color-${color})`}}>{col}</th>))}
                    </tr>
                </thead>
                <tbody>

                {tbody.map(row => (
                    <tr>
                        {row.map(col => (<td>{col}</td>))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default WorkTable;
