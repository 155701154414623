import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";
import WorkSlider from "../../../components/Work/WorkSlider/WorkSlider";
import WorkScreenshots from "../../../components/Work/WorkScreenshots/WorkScreenshots";
import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";
// import WorkVideo from "../../../components/Work/WorkVideo/WorkVideo";

import AppForControllingLightsBanner from "./images/AppForControllingLightsBanner.jpg";
import AppForControllingLightsImage from "./images/AppForControllingLights.png";
import AppForControllingLightsImage1 from "./images/AppForControllingLights-1.png";
import AppForControllingLightsImage2 from "./images/AppForControllingLights-2.png";
import AppForControllingLightsImage3 from "./images/AppForControllingLights-3.png";
import AppForControllingLightsImage4 from "./images/AppForControllingLights-4.png";
import AppForControllingLightsImage5 from "./images/AppForControllingLights-5.png";
import AppForControllingLightsImage6 from "./images/AppForControllingLights-6.png";

// @ts-ignore
// import MobileAppForMeditationsVideo from "./MobileAppForMeditations.mp4";
import WorkTable from "../../../components/Work/WorkTable/WorkTable";
import WorkImage from "../../../components/Work/WorkImage/WorkImage";

function AppForControllingLights() {
    return (
        <section className="container container-work">
            <WorkTitle>Mobile Application for Remote Light Control in Lamps 📱💡</WorkTitle>
            <WorkText title="My role" hasSeparator>UX/UI Designer</WorkText>
            <WorkText title="Team">Customer, Developers</WorkText>

            <WorkBanner images={[AppForControllingLightsBanner]} autoHeight isWithoutBG/>
            {/*<WorkVideo src={MobileAppForMeditationsVideo}/>*/}

            <WorkText title="🔍 Research" hasSeparator>
                By engaging with users of similar applications, we have investigated the core needs of our target
                audience. This understanding has allowed us to tailor functionalities that cater directly to their
                requirements.
            </WorkText>

            <WorkTable
                color="green-2"
                thead={['🗣️ Target Audience', '❤️ Main Needs']}
                tbody={[
                    [
                        'Bloggers 📝',
                        'To enhance the visual appeal of their content and engage their audience.',
                    ],
                    [
                        'Content Creators 🎨',
                        'For creative control over the ambiance and mood in their content.'
                    ],
                    [
                        'Filmmakers 🎬',
                        'To replicate various lighting conditions and improve cinematic quality.'
                    ],
                    [
                        'Photographers 📸',
                        'For controlling shadows and highlights in their shots.'
                    ],
                    [
                        'Streamers 🎮',
                        'To maintain consistent lighting and reduce glare during live streams.'
                    ]
                ]}
            />

            <WorkText title="🤳 Functionality">
                Drawing from the table provided, the following features have been developed:
                <ul>
                    <li>💡 Customizable color and brightness settings that can be saved.</li>
                    <li>🎨 Distinct icons for each lamp to facilitate easy identification.</li>
                    <li>⏰ Reminder and auto-shutoff capabilities to conserve energy.</li>
                    <li>🔆 Automated white balance adjustment for optimal lighting.</li>
                    <li>🎬 Capability to save and recall different lighting scenes.</li>
                    <li>🗣 Voice command integration for hands-free operation.</li>
                    <li>📊 Detailed energy consumption tracking for individual lamps.</li>
                </ul>
            </WorkText>

            <WorkImage src={AppForControllingLightsImage6}></WorkImage>

            <WorkText title="🤓 Challenge">
                A particular challenge we embraced was to refine the accessory pairing process, ensuring it was as
                user-friendly as possible. The application supports multiple connection methods, including Wi-Fi,
                Bluetooth, and manual setup, each designed to be straightforward and quick. Recognizing the variety of
                user preferences and technical proficiencies, we meticulously evaluated the connection flows in numerous
                similar applications. This rigorous analysis allowed us to develop a system that demystifies the setup
                process. Users can now connect their accessories with minimal effort and start enjoying the app's
                features promptly, marking a significant advancement in usability and convenience.
            </WorkText>

            <WorkScreenshots color="gray-light"
                             images={[AppForControllingLightsImage, AppForControllingLightsImage1, AppForControllingLightsImage2]}/>


            <WorkText title="💡 Design">
                Central to the app's design is the prioritization of accessibility and simplicity, with key
                functionalities prominently placed on the home screen for immediate access. The interface adopts a dark
                theme enriched with vibrant color accents that facilitate navigation and reduce eye strain, particularly
                in low-light conditions.
            </WorkText>
            <WorkSlider images={[
                AppForControllingLightsImage3,
                AppForControllingLightsImage4,
                AppForControllingLightsImage5,
                AppForControllingLightsImage3,
            ]}/>

            <WorkText title="📱 Final Product">
                The final application is crafted for ease of use, allowing users to effortlessly add new lamps and
                manage existing ones with finesse across various scenarios. The interface simplifies the configuration
                process and streamlines the control mechanism, making the management of lighting intuitive and
                responsive to the diverse needs of the user's environment.
            </WorkText>
            <WorkFigma
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FNKJPkg45SDGgIEenrhMenT%2FLights-Controler%3Ftype%3Ddesign%26node-id%3D2%253A6%26mode%3Ddesign%26t%3DQAKLK32T8rdv8Yjm-1"/>
        </section>
    );
}

export default AppForControllingLights;
