import React from 'react';
import './Header.scss';
import Icon from "../Icon/Icon";
import {NavLink} from "react-router-dom";

function Header() {
    const handleClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    return (
        <header className="Header">
            <div className="container">
                <div className="Header__inner">
                    <a className="Header__logo" href="/">
                        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M24 2.68032C24 9.5091 18.6274 22 12 22C5.37258 22 0 9.5091 0 2.68032C0 -4.14845 8.37258 4.22589 12 4.22589C15.6274 4.22589 24 -4.14845 24 2.68032Z"
                                fill="#1E1E1E"/>
                            <path id="cats-mouth" d="M11 15C11 15 11.5975 16 12 16C12.4025 16 13 15 13 15"
                                  stroke="white" stroke-linecap="round"/>
                        </svg>

                        <div className="Header__logo-text">
                            <strong>Natalie Usevych</strong>
                            <span>UX designer</span>
                        </div>
                    </a>
                    <nav className="Header__nav">
                        <NavLink onClick={handleClick} to="/">home</NavLink>
                        <NavLink onClick={handleClick} to="/about">about</NavLink>
                        <NavLink onClick={handleClick} to="/works">works</NavLink>
                        <NavLink onClick={handleClick} to="/Natalie_Usevych_UX_UI_Designer_Resume.pdf" target="_blank">resume</NavLink>
                    </nav>

                    <div className="Header__socials">
                        <NavLink to="https://www.instagram.com/natalie_ux_ui/" target="_blank">
                            <Icon name="instagram"/>
                        </NavLink>
                        <NavLink to="https://www.linkedin.com/in/natalieusevych" target="_blank">
                            <Icon name="linkedin"/>
                        </NavLink>
                        <NavLink to="https://www.behance.net/natalieusevych" target="_blank">
                            <Icon name="behance"/>
                        </NavLink>
                        <NavLink to="https://dribbble.com/natalieusevych" target="_blank">
                            <Icon name="dribble"/>
                        </NavLink>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
