import React from 'react';
import Tile from "../../components/Tile/Tile";
import TileFooter from "../../components/Tile/Footer/TileFooter";
import Picture from "../../components/Picture/Picture";
import InstagramTile from "../../components/Tile/InstagramTile/InstagramTile";
import LinkedinTile from "../../components/Tile/LinkedinTile/LinkedinTile";
import AIMentalHealthAppImage from "../Works/AIMentalHealthApp/images/AIMentalHealthApp-4.png";
import AppForControllingLightsImage from "../Works/AppForControllingLights/images/AppForControllingLights-5.png";
import MobileAppForMeditationsImage from "../Works/MobileAppForMeditations/images/MobileAppForMeditations.png";
import './Home.scss';

function Home() {
    return (
        <section className="Home">
            <div className="container">
                <div className="grid gap-6">
                    <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                        <Tile title="About Me" to="about" footer={<TileFooter/>}>
                            <p>
                                ✨ My recipe for successful design is simple: listen, observe, understand people
                            </p>
                            <p>
                                ✨ The right questions lead to the right solutions
                            </p>
                            <p>
                                ✨ User pains often hold the key to solutions
                            </p>
                            <p>
                                ✨ Until design reaches users, it's just design illusion
                            </p>
                            <p>
                                ✨ I work not just to mess around with the design system, but to help sell the product
                            </p>
                            <p>
                                ✨ Often, instead of a ton of design artifacts, all you really need is to listen well to
                                the client or users
                            </p>
                        </Tile>
                        <div>
                            <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                                <Tile title="AI Mental Health App" to="works/ai-mental-health-app">
                                    <Picture className="Home__picture" title="AI Mental Health App"
                                             imgSrc={AIMentalHealthAppImage}/>
                                </Tile>
                                <div className="grid gap-6">
                                    <InstagramTile/>
                                    <LinkedinTile/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                        <Tile title="App for controlling lights" to="works/application-for-remote-light-control">
                            <Picture className="Home__picture" title="App for controlling lights"
                                     imgSrc={AppForControllingLightsImage}/>
                        </Tile>
                        <Tile title="Meditation app" to="works/mobile-app-for-meditations">
                            <Picture className="Home__picture" title="Meditation app"
                                     imgSrc={MobileAppForMeditationsImage}/>
                        </Tile>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Home;
