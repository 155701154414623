import React from 'react';
import Tile from "../Tile";
import Picture from "../../Picture/Picture";
import VisitMyLinkedinImage from "./images/visit-my-linkedin.png";
import VisitMyLinkedinBigImage from "./images/visit-my-linkedin-big.png";
import "./LinkedinTile.scss"


const LinkedinTile: React.FC<{hasBigImage?: boolean}> = ({hasBigImage}) => {
    return (
        <Tile title="Visit my Linkedin"
              className="LinkedinTile"
              isTitleSmall
              isTitleBelow
              to="https://www.linkedin.com/in/natali-usevich"
              target="_blank">
            <Picture title="Visit my Linkedin"
                     imgSrc={hasBigImage ? VisitMyLinkedinBigImage : VisitMyLinkedinImage}/>
        </Tile>
    );
}

export default LinkedinTile;
