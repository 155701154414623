import React from 'react';

import { ReactComponent as ArrowUpTightIcon } from './icons/arrow-up-right.svg';
import { ReactComponent as BehanceIcon } from './icons/behance.svg';
import { ReactComponent as DribbleIcon } from './icons/dribble.svg';
import { ReactComponent as GoogleIcon } from './icons/google.svg';
import { ReactComponent as InstagramIcon } from './icons/instagram.svg';
import { ReactComponent as LinkedinIcon } from './icons/linkedin.svg';
import { ReactComponent as LogoIcon } from './icons/logo.svg';
import { ReactComponent as MessengerIcon } from './icons/messenger.svg';
import { ReactComponent as TelegramIcon } from './icons/telegram.svg';
import { ReactComponent as WhatsappIcon } from './icons/whatsapp.svg';

const icons = {
    "arrow-up-right": ArrowUpTightIcon,
    behance: BehanceIcon,
    dribble: DribbleIcon,
    google: GoogleIcon,
    instagram: InstagramIcon,
    linkedin: LinkedinIcon,
    logo: LogoIcon,
    messenger: MessengerIcon,
    telegram: TelegramIcon,
    whatsapp: WhatsappIcon,
};

export type IconName = keyof typeof icons;

type IconProps = {
    name: IconName;
    className?: string;
};

const Icon: React.FC<IconProps> = ({ name, className }) => {
    const IconComponent = icons[name];

    if (!IconComponent) {
        return null;
    }

    return <IconComponent className={className} />;
};

export default Icon;
