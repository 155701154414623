import React from 'react';
import './WorkFigma.scss';

type Props = {
    src: string;
};

const WorkFigma: React.FC<Props> = ({src}) => {
    return (
        <iframe className="WorkFigma" width="800" height="590"
                src={src}
                allowFullScreen></iframe>
    );
}

export default WorkFigma;
