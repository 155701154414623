import React from 'react';
import './WorkVideo.scss';

type Props = {
    src: string;
};

const WorkVideo: React.FC<Props> = ({src}) => {
    return (
        <video className="WorkVideo" autoPlay loop muted style={{width: '100%', height: 'auto'}}>
            <source src={src} type="video/mp4"/>
            Your browser does not support the video tag.
        </video>
    );
}

export default WorkVideo;
