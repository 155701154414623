import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";

import CRMSystemForSalesManagementBanner from "./images/CRMSystemForSalesManagementBanner.jpg";
import CRMSystemForSalesManagementCompare1 from "./images/CRMSystemForSalesManagementCompare1.jpg";
import CRMSystemForSalesManagementCompare2 from "./images/CRMSystemForSalesManagementCompare2.jpg";
import CRMSystemForSalesManagementImage from "./images/CRMSystemForSalesManagementImage.png";
import CRMSystemForSalesManagementItem1 from "./images/CRMSystemForSalesManagementItem1.jpg";
import CRMSystemForSalesManagementItem2 from "./images/CRMSystemForSalesManagementItem2.jpg";

import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";
import WorkImage from "../../../components/Work/WorkImage/WorkImage";
import WorkScreenshots from "../../../components/Work/WorkScreenshots/WorkScreenshots";
import WorkStickers from "../../../components/Work/WorkStickers/WorkStickers";

function CRMSystemForSalesManagement() {
    return (
        <section className="container container-work">
            <WorkTitle>
                CRM System for a Mediator Company between Sports Clubs and Their Sponsors 🏈 ⚾
            </WorkTitle>

            <WorkText title="My role" hasSeparator>UX/UI Designer</WorkText>

            <WorkText title="Team">
                Company Executives, Administrator, Developer, Sales Manager
            </WorkText>

            <WorkBanner images={[CRMSystemForSalesManagementBanner]}/>

            <WorkText title="🔍 Research" hasSeparator>
                I thoroughly examined the old sales system and the challenges faced by employees, issues that
                significantly impact their daily work and hinder its progress.
            </WorkText>

            <WorkStickers pattern="football" stickers={[
                {
                    color: 'yellow',
                    text: '“It\'s difficult to quickly find past interaction logs, leading to repeated or missed follow-ups”'
                },
                {color: 'purple', text: '“Sometimes I end up calling the same people again by mistake, and they get annoyed”'},
                {color: 'green', text: '“I get really frustrated when I can\'t properly upload something”'},
                {color: 'blue', text: '“Lost call details sometimes cause awkward follow-ups with sponsors.”'}
            ]}/>

            <WorkText title="🎯 Task">
                My task was to create a new online CRM system to replace the old one, which was installed as a computer
                program. I aimed to simplify the navigation and core processes for sales managers and administrators.
            </WorkText>

            <WorkImage src={CRMSystemForSalesManagementCompare2} compareSrc={CRMSystemForSalesManagementCompare1}/>

            <WorkText title="👩‍💻 Process">
                I closely collaborated with various company representatives to understand their old processes, in order
                to make the new processes simplified yet easy for the employees to transition to. We built all
                functionalities based on how all employees were accustomed to working. We literally recreated all their
                processes as sketches and used these to inform our design. The company being family-owned, it was
                crucial not to alter processes that had been passed down through generations.
            </WorkText>
            <WorkImage src={CRMSystemForSalesManagementImage}/>

            <WorkText title="⚙️ Functionality">
                The redesigned CRM system focuses on enhancing daily operations while maintaining the traditional
                workflow familiar to the company's employees. Key functionalities include an efficient tool for adding,
                viewing, and tracking the history of interactions with sponsors. It also offers robust features for
                managing articles and overseeing sales processes effectively. The system enables employees to handle
                call logs meticulously and maintain a comprehensive calendar for scheduling and tracking meetings and
                events. Additionally, users can perform targeted searches within the system to quickly find specific
                information, improving overall productivity and responsiveness.
            </WorkText>
            <WorkImage src={CRMSystemForSalesManagementItem1} autoHeight={true} />

            <WorkText title="👩‍🎨 Design">
                The design meets the clients' needs — it features brand colors, is simple, classic, and allows the
                developer to manage further changes independently without needing a designer on the team.
            </WorkText>
            <WorkImage src={CRMSystemForSalesManagementItem2} autoHeight={true} />

            {/*<WorkScreenshots color="gray-light"*/}
            {/*                 images={[OnlineStoreOfBrandedClothesScreenshot3, OnlineStoreOfBrandedClothesScreenshot1, OnlineStoreOfBrandedClothesScreenshot2]}/>*/}


            <WorkText title="📊 Final Product">
                As a result, the company received a system in a template form with an easy-to-use table for viewing and
                searching any necessary information for various company departments. The system looks simple, with
                intuitive navigation and processes, significantly simplifying and speeding up operations across all
                departments.
            </WorkText>
            <WorkFigma src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F7SZ9WSc1H6KOTZPJXEnsN9%2FSport-CRM-(NDA)%3Ftype%3Ddesign%26node-id%3D152%253A14715%26mode%3Ddesign%26t%3DyeXqD4AttYvn5B9b-1"/>
        </section>
    );
}

export default CRMSystemForSalesManagement;
