import React from 'react';
import './WorkImage.scss';
import Picture from "../../Picture/Picture";
import {ReactCompareSlider, ReactCompareSliderImage} from "react-compare-slider";
import classNames from "classnames";

type Props = {
    src: string;
    compareSrc?: string;
    autoHeight?: boolean;
};

const WorkImage: React.FC<Props> = ({src, compareSrc, autoHeight}) => {
    return (
        <>
            {!compareSrc && (
                <div className={classNames("WorkImage", {autoHeight})}>
                    <Picture className="WorkImage__picture" imgSrc={src}/>
                </div>
            )}

            {compareSrc && (
                <div className="WorkImage compare">
                    <ReactCompareSlider
                        className="WorkImage__compare"
                        itemOne={<ReactCompareSliderImage src={src} />}
                        itemTwo={<ReactCompareSliderImage src={compareSrc} />}
                    />
                </div>
            )}
        </>
    );
}

export default WorkImage;
