import React from 'react';
import VisitMyInstagramBlogImage from "./images/visit-my-instagram-blog.png";
import VisitMyInstagramBlogBigImage from "./images/visit-my-instagram-blog-big.png";
import Tile from "../Tile";
import Picture from "../../Picture/Picture";
import "./InstagramTile.scss"


const InstagramTile: React.FC<{hasBigImage?: boolean}> = ({hasBigImage}) => {
    return (
        <Tile title="Visit my Instagram Blog"
              className="InstagramTile"
              isTitleSmall
              isTitleBelow
              to="https://www.instagram.com/natalie_ux_ui"
              target="_blank">
            <Picture title="Visit my Instagram Blog"
                     imgSrc={hasBigImage ? VisitMyInstagramBlogBigImage : VisitMyInstagramBlogImage}/>
        </Tile>
    );
}

export default InstagramTile;
